import React, { useMemo } from 'react'
import classnames from 'classnames'
import { useStyletron } from 'styletron-react'
import { colors } from '@styles/colors'
import { icons, IconType } from '.'

export type IconProps = {
  type: IconType
  size?: number
  color?: string
  className?: string
  rotate?: number
  onClick?: React.MouseEventHandler<SVGSVGElement>
}

export const Icon = React.memo(
  ({
    type,
    size = 24,
    rotate,
    className,
    onClick,
    color = colors.gray700,
  }: IconProps) => {
    const [css] = useStyletron()
    const Component = useMemo(() => icons[type].default, [type])

    return (
      <Component
        width={size}
        height={size}
        className={classnames(
          'icon',
          className,
          css({
            minWidth: size + 'px',
            minHeight: size + 'px',
            display: 'inline-flex',
            transform: rotate ? `rotate(${rotate}deg)` : undefined,
            cursor: Boolean(onClick) ? 'pointer' : undefined,
            fill: color,
          })
        )}
        onClick={onClick}
      />
    )
  }
)
